@import '../../resources/styles/colors';
@import '../../resources/styles/fonts';

.content {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media screen and (max-width: 1367px) {
    gap: 24px;
  }
}

.main-infos {
  display: flex;
  max-width: 720px;
  flex-direction: column;
  gap: 8px;
  padding-top: 32px;

  @media screen and (max-width: 1400px) {
    padding-top: 24px;
  }

  @media screen and (max-width: 720px) {
    padding-top: 8px;
  }

  h1 {
    position: relative;
    font-family: $primary-font;
    color: $c-white;
    font-size: 56px;
    font-weight: 900;
    line-height: 108%;
    animation: startAnim 0.8s ease-out;

    @media screen and (max-width: 1367px) {
      font-size: 40px;
    }

    @media screen and (max-width: 720px) {
      font-size: 32px;
    }
  }

  h2 {
    opacity: 0;
    position: relative;
    color: $c-lgray;
    font-size: 20px;
    font-weight: 300;
    animation: startAnim 0.8s ease-out forwards;
    animation-delay: 0.5s;

    @media screen and (max-width: 1367px) {
      font-size: 18px;
    }

    @media screen and (max-width: 720px) {
      font-size: 16px;
    }

    b {
      font-weight: 600;
    }
  }
}

.instructors {
  display: flex;
  align-items: center;
  gap: 24px;
  position: relative;
  opacity: 0;
  animation: startAnim 0.8s ease-out forwards;
  animation-delay: 1s;

  @media screen and (max-width: 720px) {
    margin: 16px 0;
    justify-content: center;
  }

  span {
    font-size: 24px;
    color: $c-white;
  }

  a {
    text-decoration: none;
  }
}

.instructor {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  transition: transform 0.3s ease;
  cursor: pointer;

  &:hover {
    transition: transform 0.3s ease;
    transform: translateY(-5px);
  }

  img {
    @media screen and (max-width: 720px) {
      width: 80px;
      height: 80px;
    }
  }

  &__name {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 16px;
    color: $c-white;

    @media screen and (max-width: 720px) {
      font-size: 13px;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }
}

.social {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 1367px) {
    gap: 8px;
  }

  p {
    color: $c-lgray;
    font-size: 16px;
    font-weight: 300;

    @media screen and (max-width: 1367px) {
      font-size: 15px;
    }

    @media screen and (max-width: 720px) {
      font-size: 13px;
      text-align: center;
    }
  }

  &__medias {
    display: flex;
    gap: 12px;

    @media screen and (max-width: 720px) {
      justify-content: center;
      gap: 8px;
    }

    img {
      transition: all 0.2s ease-out;
      width: 36px;
      height: 36px;
      cursor: pointer;

      &:hover {
        transition: all 0.2s ease-out;
        transform: scale(1.1);
      }
    }
  }
}

@keyframes startAnim {
  0% {
    bottom: -80px;
    opacity: 0;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes footerAnim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.--none {
  display: none;
}
