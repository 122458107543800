@import '../../resources/styles/colors';

.container {
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  min-height: 100%;
  background: linear-gradient(90deg, $c-black 0%, rgba(17, 20, 23, 0) 100%),
    linear-gradient(90deg, $c-black 0%, rgba(17, 20, 23, 0) 100%);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 720px) {
    background: linear-gradient(
        0deg,
        rgba(17, 20, 23, 0) 10%,
        rgba(17, 20, 23, 1) 100%
      ),
      linear-gradient(0deg, rgba(17, 20, 23, 0) 10%, rgba(17, 20, 23, 1) 100%);
  }
}
