@import '../../resources/styles/colors';
@import '../../resources/styles/fonts';

.cta-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cta-title {
  color: $c-lgray;
  position: relative;
  opacity: 0;
  font-size: 16px;
  animation: startAnim 0.8s ease-out forwards;
  animation-delay: 1s;

  @media screen and (max-width: 720px) {
    font-size: 13px;
  }
}

.cta {
  position: relative;
  opacity: 0;
  display: flex;
  gap: 16px;
  animation: startAnim 0.8s ease-out forwards;
  animation-delay: 1s;

  @media screen and (max-width: 720px) {
    flex-direction: column;
  }

  .email {
    input {
      transition: border 0.2s ease-out;
      position: relative;
      font-family: $primary-font;
      display: flex;
      align-items: center;
      background: rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(2px);
      box-sizing: border-box;
      font-size: 24px;
      border-radius: 20px;
      font-weight: 400;
      color: $c-lgray;
      position: relative;
      width: 376px;
      height: 72px;
      padding: 0 24px;
      border: 1px solid $c-white;
      animation: borderAnim 4s ease infinite;

      @media screen and (max-width: 1367px) {
        font-size: 20px;
        height: 64px;
      }

      @media screen and (max-width: 720px) {
        border-radius: 16px;
        width: 100%;
        font-size: 18px;
        height: 48px;
        padding: 0 16px;
      }

      &:hover,
      &:focus {
        transition: border 0.2s ease-out;
        border: 1px solid $c-brand-green;
      }
    }
  }

  .subscribe-button {
    transition: transform 0.2s ease-out;
    font-family: $primary-font;
    display: flex;
    height: 72px;
    padding: 0px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: linear-gradient(225deg, $c-brand-blue 0%, $c-brand-green 100%),
      $c-white;
    border: 0;
    color: $c-lgray;
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0px 0px 0px 0px rgba(1, 175, 195, 0.25);
    animation: gradient 4s ease infinite;
    background-size: 150% 150%;

    &:disabled {
      background-color: #ccc;
      color: #888;
      cursor: not-allowed;
    }

    @media screen and (max-width: 1367px) {
      font-size: 20px;
      height: 64px;
    }

    @media screen and (max-width: 720px) {
      border-radius: 16px;
      width: 100%;
      font-size: 18px;
      height: 48px;
    }

    @keyframes gradient {
      0% {
        background-position: 0% 50%;
        box-shadow: 0px 0px 0px 0px rgba(1, 175, 195, 0);
      }

      50% {
        background-position: 100% 50%;
        box-shadow: 0px 4px 16px 0px rgba(1, 175, 195, 0.25);
      }

      100% {
        background-position: 0% 50%;
        box-shadow: 0px 0px 0px 0px rgba(1, 175, 195, 0);
      }
    }

    &:hover {
      transition: transform 0.2s ease-out;
      transform: scale(1.05);
    }
  }
}

.legal {
  position: relative;
  opacity: 0;
  animation: startAnim 0.8s ease-out forwards;
  animation-delay: 1s;

  &__privacy,
  &__disclaimer {
    color: $c-lgray;
    font-weight: 300;
    margin: 0;
    padding: 0;
    font-size: smaller;
    font-size: 12px;

    @media screen and (max-width: 720px) {
      font-size: 12px;
    }
  }
}

.success {
  color: $c-brand-green;
  font-size: 14px;

  span {
    a {
      text-decoration: underline;
      color: $c-brand-green;
    }
  }
}

.error {
  color: $c-error;
  font-size: 14px;
}

.loading-spinner {
  animation: loading 1s linear infinite;
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}
