@import './colors';
@import './fonts';

::-moz-selection {
  /* Code for Firefox */
  color: $c-lgray;
  background: $c-brand-blue;
}

::selection {
  color: $c-lgray;
  background: $c-brand-blue;
}

* {
  outline: none;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  font-family: $primary-font;
}

body {
  background-image: url('../imgs/Bg.jpg');
  background-size: cover;
  background-position: 50%;
  background-attachment: fixed;
}

h1,
h2,
h3,
h4,
p,
a {
  margin: 0;
  padding: 0;
  font-style: normal;
}

a {
  font-weight: 00;
  color: $c-lgray;
  cursor: pointer;
}

.blink {
  display: none;
}
