$primary-font: 'Source Sans 3', sans-serif;

@font-face {
  font-family: 'Source Sans 3';
  src:
    url('../fonts/SourceSans3-Black.woff2') format('woff2'),
    url('../fonts/SourceSans3-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans 3';
  src:
    url('../fonts/SourceSans3-Light.woff2') format('woff2'),
    url('../fonts/SourceSans3-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans 3';
  src:
    url('../fonts/SourceSans3-Regular.woff2') format('woff2'),
    url('../fonts/SourceSans3-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans 3';
  src:
    url('../fonts/SourceSans3-SemiBold.woff2') format('woff2'),
    url('../fonts/SourceSans3-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
