@import '../../resources/styles/colors';

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 48px 0 32px 0;

  @media screen and (max-width: 720px) {
    padding: 24px 0;
  }
}

.lutick {
  height: 20px;

  @media screen and (max-width: 720px) {
    height: 16px;
  }
}

.logo-wrapper {
  position: relative;

  .logo {
    height: 70px;

    @media screen and (max-width: 1367px) {
      height: 56px;
    }

    @media screen and (max-width: 720px) {
      height: 48px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 22px;
    background: #00b0c3;
    right: 26px;
    bottom: 7px;
    animation: blink 2s infinite;
    animation-timing-function: steps(2, end);

    @media screen and (max-width: 1367px) {
      height: 18px;
      right: 20px;
      bottom: 7px;
    }

    @media screen and (max-width: 720px) {
      height: 14px;
      right: 15px;
      bottom: 6px;
    }
  }
}

@keyframes blink {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
