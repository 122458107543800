@import '../../resources/styles/colors';

.main {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  min-height: 100%;
  height: 100%;
  padding: 0 40px;

  @media screen and (max-width: 720px) {
    padding: 0 20px;
  }
}
