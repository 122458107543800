@import '../../resources/styles/colors';

.footer {
  opacity: 0;
  position: absolute;
  padding: 0 40px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto 48px auto;
  box-sizing: border-box;
  animation: footerAnim 0.8s ease-out forwards;
  animation-delay: 2s;

  @media screen and (max-height: 910px) {
    position: relative;
    padding: 32px 40px;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 720px) {
    margin-bottom: 0;
    padding: 20px 20px;
  }

  p {
    color: $c-lgray;
    font-size: 16px;
    font-weight: 300;

    @media screen and (max-width: 1367px) {
      font-size: 16px;
    }

    @media screen and (max-width: 1367px) {
      font-size: 14px;
    }

    a {
      font-weight: 600;
      color: $c-lgray;
    }
  }
}
